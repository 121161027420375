@import 'colors';
@import 'layout';
@import 'transformations';
@import 'typography';
@import '../../node_modules/bootstrap/scss/bootstrap';
@import '../../node_modules/react-toastify/scss/main';
@import 'variables';

.clickable {
  cursor: pointer;
}

.Toastify__toast-body {
  white-space: pre-line;
}

body {
  background-image: url('/mountain.jpg');
  background-size: cover;
}
